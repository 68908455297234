import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Faq = Loadable(lazy(() => import('modules/faqs/pages/Index')));

export const faqsRoutes = [
  {
    path: '/faq',
    element: <Faq />,
  },
];
